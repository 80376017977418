'use strict'
import '../../styles/blocks/_payray-in-press.scss'
import Swiper, {EffectCreative, Navigation} from 'swiper';
import 'swiper/scss'
import 'swiper/scss/effect-creative'
import 'swiper/scss/pagination';
import {breakpoints} from '../breakpoints'

Swiper.use([Navigation, EffectCreative]);

const initSlider = () => {
    const slider = new Swiper('.js-slider-in-press .swiper', {
        effect: "creative",
        loop: true,
        speed: 550,
        uniqueNavElements: false,
        navigation: {
            nextEl: '.swiper-button-next--in-press',
            prevEl: '.swiper-button-prev--in-press',
        },
        breakpoints: {
            0: {
                slidesPerView: 1.09,
                creativeEffect: {
                    limitProgress: 2,
                    prev: {
                        translate: ["-100%", 0, 0],
                    },
                    next: {
                        translate: ["+100%", 0, 0],
                    }
                },
            },
            [breakpoints.lg]: {
                slidesPerView: 2,
                creativeEffect: {
                    limitProgress: 20,
                    prev: {
                        opacity: 0,
                        translate: [0, 0, -300],
                    },
                    next: {
                        translate: ["+100%", 0, 0],
                    }
                },
            }
        },
    });

}

$(window).on('load', function () {
    initSlider()
});
